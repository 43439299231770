import { TextContentWithMediaFragment } from '../../graphql-types';
import { TextContentWithMediaProps } from '../components/molecules/text-content-with-media';
import { AvailableLocales } from '../locale';
import { mapFragmentToCtaProps } from './cta.mapper';
import { mapFragmentToMediaProps } from './media.mapper';
import { safeApply } from './utils';

export const mapFragmentToContentImageWithTextProps = (
  fragment: TextContentWithMediaFragment,
  locale: AvailableLocales
): TextContentWithMediaProps => {
  return {
    contentOrder: fragment.order ?? 'text_image',
    media: mapFragmentToMediaProps(fragment.media, fragment.youtube_embed_id ?? '', locale, fragment.media_mobile),
    title: fragment.title ?? '',
    cta: safeApply(value => mapFragmentToCtaProps(value), fragment.link),
    text: fragment.text ?? '',
  };
};
