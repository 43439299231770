import React from 'react';
import styled from '@emotion/styled';

export type HeaderSectionProps = {
  background?: string;
  id?: string;
};

const HeaderSectionWrapper = styled.section<HeaderSectionProps>`
  background-color: ${({ background }) => (background ? background : 'none')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.sectionGap};
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const HeaderSection: React.FC<HeaderSectionProps> = props => {
  return <HeaderSectionWrapper {...props}>{props.children}</HeaderSectionWrapper>;
};
