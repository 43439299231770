import React from 'react';

import { Section } from '../components/molecules/section';
import { HeaderSection } from '../components/molecules/header-section';
import { TextContentWithMedia, TextContentWithMediaProps } from '../components/molecules/text-content-with-media';
import { MarkdownOnImage, MarkdownOnImageProps } from '../components/molecules/markdown-on-image';
import { AlignedMarkdown } from '../components/atoms/aligned-markdown';

export type KeringFoundationProps = {
  introduction?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  textOnBackgroundImage?: MarkdownOnImageProps;
  sections?: Array<TextContentWithMediaProps>;
};

export const KeringFoundationTemplate: React.FC<KeringFoundationProps> = props => {
  return (
    <>
      {props.introduction && (
        <HeaderSection>
          <AlignedMarkdown markdown={props.introduction} align={props.textAlign} maxWidth="body_text" />
        </HeaderSection>
      )}
      {props.textOnBackgroundImage && (
        <Section firstChildMarginPreset="bottom">
          <MarkdownOnImage {...props.textOnBackgroundImage} />
        </Section>
      )}

      {props.sections?.map(section => (
        <Section key={section.title}>
          <TextContentWithMedia {...section} />
        </Section>
      ))}
    </>
  );
};
