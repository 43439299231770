import { AlignedMarkdownFragment, TextOnBackgroundImageFragment } from '../../graphql-types';
import { AlignedMarkdownProps } from '../components/atoms/aligned-markdown';
import { MarkdownOnImageProps } from '../components/molecules/markdown-on-image';
import { AvailableLocales } from '../locale';
import { mapFragmentToImageProps } from './image.mapper';

export const mapFragmentToTextOnBackgroundImageProps = (
  fragment: TextOnBackgroundImageFragment,
  locale: AvailableLocales
): MarkdownOnImageProps => {
  return {
    text: {
      markdown: fragment.text ?? '',
      align: fragment.text_alignment ?? undefined,
    },
    background: mapFragmentToImageProps(fragment.background_image, locale, fragment.background_image_mobile),
    mobileDisplayMode: fragment.mobile_display_mode ?? 'text_below',
  };
};

export const mapFragmentToAlignedMarkdownProps = (fragment: AlignedMarkdownFragment): AlignedMarkdownProps => {
  return {
    markdown: fragment.text ?? '',
    align: fragment.text_alignment_md ?? undefined,
    maxWidth: fragment.content_width ?? 'full_width',
  };
};
