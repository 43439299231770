import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { KeringFoundationQuery } from '../../graphql-types';
import { KeringFoundationTemplate } from '../templates/kering-foundation.template';
import { parseLocale } from '../locale';
import { mapFragmentToContentImageWithTextProps } from '../data-mappers/kering-foundation.mapper';
import SEO from '../components/seo';
import { mapFragmentToTextOnBackgroundImageProps } from '../data-mappers/markdown.mapper';
import { safeApply, safeMap } from '../data-mappers/utils';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const query = graphql`
  query KeringFoundation($language: String) {
    cms {
      keringFoundation(locale: $language) {
        metas {
          id
          description
        }
        introduction
        title
        text_alignment
        TextOnBackgroundImage {
          ...TextOnBackgroundImage
        }
        Sections {
          ...TextContentWithMedia
        }
      }
    }
  }
`;

const KeringFoundation: React.FC<{ data: KeringFoundationQuery }> = ({ data }) => {
  const { cms } = data;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const textOnBackgroundImage = safeApply(
    value => mapFragmentToTextOnBackgroundImageProps(value, locale),
    cms.keringFoundation?.TextOnBackgroundImage
  );

  const sections = safeMap(
    section => mapFragmentToContentImageWithTextProps(section, locale),
    cms.keringFoundation?.Sections
  );

  useBreadcrumbs({
    contentCategory: intl.formatMessage({ id: 'kering_foundation' }),
  });

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'kering-foundation' },
      { locale: 'fr', slug: 'kering-foundation' },
    ],
  });

  return (
    <>
      <SEO
        lang={locale}
        title={cms.keringFoundation?.title ?? ''}
        description={cms.keringFoundation?.metas?.description ?? ''}
      />
      <KeringFoundationTemplate
        introduction={cms.keringFoundation?.introduction ?? undefined}
        textAlign={cms.keringFoundation?.text_alignment ?? undefined}
        textOnBackgroundImage={textOnBackgroundImage}
        sections={sections}
      />
    </>
  );
};

export default KeringFoundation;
